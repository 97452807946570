import { baseTheme, PaletteType } from '@groupbuilderoy/gb-components-library';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { ThemeFonts } from 'context/App/types';
import { setupFonts } from './setupFonts';

const customOverrides: Record<string, any> = {
  hartela: {
    MuiAlert: {
      outlinedInfo: {
        color: '#4d239d',
        border: '1px solid #4d239d',
        borderRadius: '0px'
      },
      icon: {
        color: '#4d239d !important'
      }
    }
  },
  srv: {
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: `#f44336 !important`
        },
        '&.Mui-focused': {
          color: 'black'
        }
      }
    },
    MuiInputLabel: {
      root: {
        '&.Mui-error': {
          color: `#f44336 !important`
        },
        '&.Mui-focused': {
          color: 'black'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `#f44336 !important`
          }
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `black`
          }
        }
      }
    }
  }
};

const defaultTheme = (customPalette?: PaletteType, fonts?: ThemeFonts, organisation?: string) => {
  const { typography } = setupFonts(fonts, organisation);

  const themeProps: ThemeOptions = {
    ...baseTheme({
      palette: customPalette,
      typography: typography
      // headersTypography: '',
    }),
    name: 'default',
    overrides:
      organisation && customOverrides[`${organisation}`] ? customOverrides[organisation] : {}
  };
  return createTheme(themeProps);
};

export default defaultTheme;
